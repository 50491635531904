* {
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.2s;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000000000s 0s, color 600000000000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

::-webkit-scrollbar {
  width: 2px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

body {
  background: rgba(0, 0, 0, 0.65);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
